import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import WishQueue from "../Components/WishQueue";
import SpotifyService from '../Services/SpotifyService';

const WishQueueContainer = (props) => {
  const cookies = new Cookies();
  const [wishList, setWishList] = useState([]);

  useEffect(() => {
    setWishListWithStatus();
    const interval = setInterval(() => setWishListWithStatus(), 10 * 1000);
    document.title = "Neon Nights - Meine Wunschliste";
    return () => {
      clearInterval(interval);
    }
  }, []);

  const setWishListWithStatus = async () => {
    try {
      const response = await SpotifyService.currentlyPlaying();
      const queue = response.body.queue;
      const queueSongIds = queue.map((q) => q.id);
      const cookiesWishLisFromCookies = cookies.get('cookiesWishList');
      if (cookiesWishLisFromCookies) {
        const cookiesWishList = cookiesWishLisFromCookies.map((q) => {

          if (q["isApproved"] && !q["wasPlayed"] && !queueSongIds.includes(q.id)) {
            q["isRejected"] = true;
            return q;
          }

          if (!q["isApproved"]) {
            q["isApproved"] = queueSongIds.includes(q.id);
          }
          return q;
        });
        setWishList(cookiesWishList);
        cookies.set('cookiesWishList', cookiesWishList);
      }
    } catch (e) {
      setWishList([]);
    }
  }

  return <WishQueue wishList={wishList} />
}
export default WishQueueContainer;