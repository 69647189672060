import Cookies from 'universal-cookie';
import { searchSongsUrl, playlistSongsUrl, addSongToQueueUrl, findLyricsUrl, currentlyPlayingUrl, getAccessTokenUrl, getSongSuggestionsUrl } from "../Utils/Urls";
import http from "../Utils/HttpService";

class SpotifyService {

  static async seach(body) {
    const response = await http.post(`${searchSongsUrl}`, body);
    const { data } = response;
    return data;
  }

  static async playlistSongs() {
    const response = await http.get(`${playlistSongsUrl}`);
    const { data } = response;
    return data;
  }

  static async addSongToQueue(body) {
    const response = await http.post(`${addSongToQueueUrl}`, body);
    const { data } = response;
    return data;
  }

  static async findLyrics(body) {
    const response = await http.post(`${findLyricsUrl}`, body);
    const { data } = response;
    return data;
  }

  static async currentlyPlaying() {
    const cookies = new Cookies();
    const response = await http.get(`${currentlyPlayingUrl}`);
    const { data } = response;
    const cookiesWishLisFromCookies = cookies.get('cookiesWishList');
    
    if (cookiesWishLisFromCookies) {
      const response_data = data.body
      const cookiesWishList = cookiesWishLisFromCookies.map((q) => {
        if (q.id === response_data.id) {
          q["wasPlayed"] = true;
          return q;
        }
        return q;
      });
      cookies.set('cookiesWishList', cookiesWishList);
    }
    return data;
  }

  static async getAccessToken(body) {
    const response = await http.post(`${getAccessTokenUrl}`, body);
    const { data } = response;
    return data;
  }

  static async getSongSuggestions() {
    const response = await http.get(`${getSongSuggestionsUrl}`);
    const { data } = response;
    return data;
  }


}

export default SpotifyService;